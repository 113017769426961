import Spinner from 'components/spinner/spinner.component';
import React from 'react';
import MessageExpiration from '../message-card/message-expiration/message-expiration.component';
import { Box, Grid } from '@material-ui/core';
import IncButton from 'components/inc-button/inc-button.component';
import { IncButtonDarkIcon } from 'components/inc-button/inc-button.styles';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@material-ui/icons/Delete';
import * as S from 'pages/account-inbox/account-inbox-message/account-inbox-message.styles';
import ARImage from 'components/ar-image/ar-image.component';
import CheckoutPromocodeButtons from '../account-inbox-message/checkout-promocode-buttons/checkout-promocode-buttons.component';

const AccountInboxItem = ({
  dialogContent,
  isLoading,
  isMessage,
  checkoutPromoCode,
  setModalOpen,
  handleDelete,
  handleClose,
  applyDiscount,
  message,
}) => {
  const { t } = useTranslation();
  const universalOffer = message?.universalOffer;
  const expireDate = message?.redemptionEndDate ?? message?.distributedEndDate;
  const image = message?.mediumImage;

  return (
    <>
      {image && <ARImage src={image} alt="" />}
      {dialogContent}
      {isLoading && <Spinner />}
      <S.ActionsGrid>
        {!!expireDate && !isMessage && (
          <Box
            marginBottom="10px"
            data-testid="AccountInboxMessage.CustomDialog.ExpirationDateTime"
          >
            <MessageExpiration
              expireDate={expireDate}
              redemptionEndDateType={message?.redemptionEndDateType}
            />
          </Box>
        )}
        <Grid container spacing={3}>
          {!checkoutPromoCode ? (
            <>
              <Grid item xs={universalOffer ? 12 : 6}>
                <IncButton
                  onClick={() => setModalOpen(false)}
                  data-testid="AccountInboxMessage.CustomButton.Close"
                  fullWidth
                >
                  {t('accountInbox.closeAction')}
                </IncButton>
              </Grid>
              {!universalOffer && (
                <Grid item xs={6}>
                  <IncButtonDarkIcon
                    onClick={handleDelete}
                    data-testid="AccountInboxMessage.CustomButton.Delete"
                    fullWidth
                  >
                    {t('accountInbox.deleteAction')}
                    <DeleteIcon />
                  </IncButtonDarkIcon>
                </Grid>
              )}
            </>
          ) : (
            <CheckoutPromocodeButtons
              message={message}
              handleClose={handleClose}
              setModalOpen={setModalOpen}
              applyDiscount={applyDiscount}
            />
          )}
        </Grid>
      </S.ActionsGrid>
    </>
  );
};

export default AccountInboxItem;
