import { DateTime } from 'luxon';
import React from 'react';
import ScheduleIcon from '@material-ui/icons/Schedule';
import * as S from './message-date.styles';
import { Box } from '@material-ui/core';

const MessageDate = ({ date }) => {
  return (
    <S.Date tabIndex="0">
      <Box marginRight="5px" display="inline-flex">
        <ScheduleIcon fontSize="inherit" />
      </Box>
      <Box>
        {DateTime.fromISO(date, { zone: 'utc' }).toRelative({
          padding: -90000,
          round: true,
        })}
      </Box>
    </S.Date>
  );
};

export default MessageDate;
