import { useURLSearchParams } from 'hooks/useURLSearchParams';
import { defaultOffers } from '../view-offers.utils';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

export const useViewOfferInbox = () => {
  const { t } = useTranslation();
  const query = useURLSearchParams();

  const offer = useMemo(
    () => ({
      distributedMessageId: '7cd24c75-395f-4433-afc3-ce6b3940aeee',
      merchantId: '4c4ade61-7973-46f0-820f-4bf5bc783e0c',
      messageId: '7b4dcb56-4dc0-44dd-af69-d61d07fedf14',
      languageCode: 'EN',
      title: query.get('title') ?? t('inboxMessage.title'),
      shortDescription:
        query.get('shortDescription') ?? t('inboxMessage.shortDescription'),
      longDescription:
        query.get('longDescription') ?? t('inboxMessage.longDescription'),
      smallImage: query.get('smallImage') ?? '',
      mediumImage: query.get('mediumImage') ?? '',
      status: 'READ',
      distributedDate: '2023-11-17T10:53:37',
      updatedDate: '2023-11-17T10:53:37',
      messageType: 'SHAREABLE',
      surveyId: null,
      distributedEndDate: '3024-11-17T10:53:37',
    }),
    [query, t],
  );

  defaultOffers.push(offer);

  return {
    finalContent: defaultOffers,
    containerAriaAttributes: {},
    finalTotal: 3,
    offer,
  };
};
