import { Box } from '@material-ui/core';
import React from 'react';
import Theme from 'Theme';
import { useViewOfferInbox } from './view-offer-inbox.hooks';
import AccountInboxItem from 'pages/account-inbox/account-inbox-item/account-inbox-item.component';
import DefaultMessageView from 'pages/account-inbox/account-inbox-message/default-message-view/default-message-view.component';

const ViewOfferInbox = () => {
  const { offer } = useViewOfferInbox();
  const dummyFunc = () => null;

  return (
    <Theme>
      <Box data-testid="ViewOffer.Inbox" p={2}>
        <AccountInboxItem
          setModalOpen={dummyFunc}
          handleDelete={dummyFunc}
          handleClose={dummyFunc}
          message={offer}
          dialogContent={
            <DefaultMessageView
              title={offer?.title}
              shortDescription={offer?.shortDescription}
              longDescription={offer?.longDescription}
              mediumImage={offer?.mediumImage}
            />
          }
        />
      </Box>
    </Theme>
  );
};

export default ViewOfferInbox;
