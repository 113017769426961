export const defaultOffers = [
  {
    distributedMessageId: '7cd24c75-395f-4433-afc3-ce6b3940aee1',
    merchantId: '4c4ade61-7973-46f0-820f-4bf5bc783e0c',
    messageId: '7b4dcb56-4dc0-44dd-af69-d61d07fedf15',
    languageCode: 'EN',
    title: 'Buy one and get one free',
    shortDescription: 'Buy one and get one free',
    longDescription: 'Buy one and get one free',
    smallImage:
      'https://s3.amazonaws.com/incentdev/1b63163d-1aba-4944-bbc1-ebc495d9dc5c/m/Discount_5c0860653a5a4f0ab745fa7f63e6e9c8.jpg',
    mediumImage:
      'https://s3.amazonaws.com/incentdev/1b63163d-1aba-4944-bbc1-ebc495d9dc5c/m/Discount_5c0860653a5a4f0ab745fa7f63e6e9c8.jpg',
    status: 'READ',
    distributedDate: '2023-11-17T10:53:37',
    updatedDate: '2023-11-17T10:53:37',
    messageType: 'SHAREABLE',
    surveyId: null,
    distributedEndDate: null,
  },
  {
    distributedMessageId: '0f005361-053f-415b-ae5c-9349cfae6e8d',
    merchantId: '4c4ade61-7973-46f0-820f-4bf5bc783e0c',
    messageId: '8e42147a-068b-4bc8-8cc5-9806371211ea',
    languageCode: 'EN',
    title: '10% modifier offer',
    shortDescription: '10% modifier offer',
    longDescription: '10% modifier offer',
    smallImage:
      'https://s3.amazonaws.com/incentdev/1b63163d-1aba-4944-bbc1-ebc495d9dc5c/o/custard-pudding-00_3ba1991294e14843a77c37292a6d26ce_1cd9e8c1da644079a2120e45f2febf8c.jpg',
    mediumImage:
      'https://s3.amazonaws.com/incentdev/1b63163d-1aba-4944-bbc1-ebc495d9dc5c/o/custard-pudding-00_3ba1991294e14843a77c37292a6d26ce_1cd9e8c1da644079a2120e45f2febf8c.jpg',
    status: 'READ',
    distributedDate: '2023-11-16T10:47:51',
    updatedDate: '2023-11-16T10:47:51',
    messageType: 'SHAREABLE',
    surveyId: null,
    distributedEndDate: null,
  },
];
